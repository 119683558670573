import {getInnerPointer, getPointer} from '../../utils/pointerUtils'
const name = 'ActiveModesPointerAspect'
const pointerType = 'activeModes'

const createPointersMethods = () => {
    const getPageActiveModes = pageId => getPointer('activeModes', pointerType, [pageId])
    const getAllActiveModes = () => getPointer('activeModes', pointerType)

    const getModes = pointer => getInnerPointer(pointer, ['modes'])
    const getModesDefinitions = pointer => getInnerPointer(pointer, ['modes', 'definitions'])
    const getModesOverrides = pointer => getInnerPointer(pointer, ['modes', 'overrides'])

    return {
        activeModes: {
            getPageActiveModes,
            getAllActiveModes
        },
        general: {
            getActiveModes: getAllActiveModes
        },
        componentStructure: {
            getModes,
            getModesDefinitions,
            getModesOverrides
        }
    }
}

const functionLibrary = {
    pointer_type_activeModesPointer: createPointersMethods
}

export {
    name,
    functionLibrary,
    pointerType
}
