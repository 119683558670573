const getMediaPrivateApis = siteApiData => {
    const registerStateChange = (listenerId, playerId, callback) => {
        siteApiData.updatePlayerStateListener(listenerId, playerId, callback)
    }

    const unregisterStateChange = (listenerId, playerId) => {
        registerStateChange(listenerId, playerId)
    }

    return {
        registerStateChange,
        unregisterStateChange
    }
}

export const defaultModel = {}

const name = 'privateApiMediaExtension'

const functionLibrary = {
    getMediaPrivateApis
}

export {
    name,
    functionLibrary
}
