import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {getPointer} from '../../utils/pointerUtils'
const name = 'renderFlagsPointerAspect'
const pointerType = 'renderFlags'

const createPointersMethods = () => {
    const getRenderFlag = flagName => getPointer(pointerType, pointerType, [flagName])
    const getRenderFlags = () => getPointer(pointerType, pointerType)
    return {
        general: {
            getRenderFlag,
            getRenderFlags
        },
        renderFlags: {
            getRenderFlag,
            getRenderFlags
        }
    }
}

const functionLibrary = {
    pointer_type_renderFlags: createPointersMethods,
    setRenderFlag: withActions((aspectActions, renderFlags, value, id, innerPath) => {
        if (innerPath) {
            aspectActions.setRootRenderFlag(innerPath[0], value)
        } else {
            //Adding an option to set the full renderFlags, but comparing with current
            //Cannot remove a flag this way, which is not supposed to be a valid case
            _.forEach(value, (flagValue, flagName) => {
                if (!_.isEqual(renderFlags[flagName], flagValue)) {
                    aspectActions.setRootRenderFlag(flagName, flagValue)
                }
            })
        }
    }),
    getRenderFlags: withActions((aspectActions, renderFlags, id, innerPath) => innerPath ?
        renderFlags[innerPath[0]] :
        renderFlags
    )
}

export {
    name,
    functionLibrary,
    pointerType
}
