import {isControllerVisible} from 'santa-core-utils/dist/cjs/coreUtils/core/appManifest/controllerStageData/controllerStageDataUtils'

const name = 'PlatformPointers'
const defaultModel = {
    // this is init in DS if we have platform:
    // platform: {
    //     appState: {},
    //     appManifest: {},
    //     appPublicApiName: {}
    // }
}

const functionLibrary = {
    isControllerVisible
}
export {name, defaultModel, functionLibrary}
