import * as mockSiteApiExtension from './mock/mockPrivateAPI'
import * as commonSiteApiExtension from './common/commonPrivateAPI'
import * as previewAnimationApiExtension from './previewAnimation/previewAnimationPrivateAPI'
import * as modesApiExtension from './modes/modesPrivateAPI'
import * as membersApiExtension from './members/membersPrivateAPI'
import * as measuringSiteApiExtension from './measuring/measuringPrivateAPI'
import * as pagesApiExtension from './pages/pagesPrivateAPI'
import * as mediaApiExtension from './media/mediaPrivateAPI'
import * as platformApiExtension from './platform/platformPrivateAPI'
import * as livePreviewApiExtension from './livePreview/livePreviewPrivateAPI'
import * as layoutApiExtension from './layout/layoutPrivateAPI'
import * as viewModeApiExtension from './viewMode/viewModePrivateAPI'
import * as scrollTrackerApiExtension from './scroll/scrollTrackerAPI'
import * as tpaApiExtension from './tpa/tpaPrivateAPI'
import * as stylableApiExtension from './stylable/stylableSiteAPI'
import * as selectiveDownloadExtension from './selectiveDownload/selectiveDownloadPrivateAPI'

export const siteApiExtensions = [
    mockSiteApiExtension,
    commonSiteApiExtension,
    measuringSiteApiExtension,
    previewAnimationApiExtension,
    modesApiExtension,
    membersApiExtension,
    pagesApiExtension,
    mediaApiExtension,
    platformApiExtension,
    livePreviewApiExtension,
    layoutApiExtension,
    viewModeApiExtension,
    scrollTrackerApiExtension,
    tpaApiExtension,
    stylableApiExtension,
    selectiveDownloadExtension
]
