import _ from 'lodash'
import {VIEW_MODES, MASTER_PAGE_ID, COMP_MODES_TYPES} from '../../../../constants/constants'
const name = 'privateApiModesExtension'

const getModesPrivateApis = (siteApiData, dal, pointers) => {
    const isMobileView = () => siteApiData.viewMode.isMobileView
    const getViewMode = () => isMobileView() ? VIEW_MODES.MOBILE : VIEW_MODES.DESKTOP
    const getCurrentUrlPageId = _.constant(siteApiData.viewer.currentUrlPageId)

    const getShowOnSomePagesModes = () => {
        const modesPointer = pointers.activeModes.getPageActiveModes(MASTER_PAGE_ID)

        if (!modesPointer) {
            return []
        }
        const modesDefinitions = _.get(dal.get(modesPointer), 'definitions', [])
        return _.filter(modesDefinitions, {type: COMP_MODES_TYPES.SHOW_ON_SOME_PAGES})
    }

    const getAllActiveModeIds = () => siteApiData.modes.activeModes

    const getSOSPModePages = modeDef => {
        const pagesGroupId = modeDef.settings.pagesGroupId.replace('#', '')
        const modePagesGroupPointer = pointers.data.getDataItem(pagesGroupId, MASTER_PAGE_ID)

        return dal.get(modePagesGroupPointer).pages
    }

    const masterPagePointer = {
        id: 'masterPage',
        type: getViewMode()
    }

    const deactivateModeById = modeId => {
        siteApiData.modes.updateActiveModes(modeId, false)
    }

    const deactivateMode = (compPointer, modeId) => {
        deactivateModeById(modeId)
    }

    const activateMode = (compPointer, modeId) => {
        const definitionsPointer = pointers.componentStructure.getModesDefinitions(compPointer)
        const modesDefinitions = dal.get(definitionsPointer)
        _.forEach(modesDefinitions, definition => {
            siteApiData.modes.updateActiveModes(definition.modeId, false)
        })
        siteApiData.modes.updateActiveModes(modeId, true)
    }

    const switchModes = (compPointer, modeIdToDeactivate, modeIdToActivate) => {
        if (modeIdToDeactivate !== modeIdToActivate) {
            deactivateMode(compPointer, modeIdToDeactivate)
            activateMode(compPointer, modeIdToActivate)
        }
    }

    const getPageActiveModes = pageId => {
        const pageActiveModesPointer = pointers.activeModes.getPageActiveModes(pageId)
        const currentRootModes = dal.get(pageActiveModesPointer)
        return _.pickBy(currentRootModes)
    }

    const updateActiveSOSPModes = pageId => {
        pageId = pageId || getCurrentUrlPageId()
        const sospModes = getShowOnSomePagesModes()
        if (sospModes) {
            const activeModes = getPageActiveModes(MASTER_PAGE_ID)

            let modeIdToActivate
            let modeIdToDeactivate
            _.forEach(sospModes, modeDefinition => {
                const modePages = getSOSPModePages(modeDefinition)

                if (_.includes(modePages, `#${pageId}`)) {
                    if (!activeModes[modeDefinition.modeId]) {
                        modeIdToActivate = modeDefinition.modeId
                    }
                } else if (activeModes[modeDefinition.modeId]) {
                    modeIdToDeactivate = modeDefinition.modeId
                }
            })

            if (modeIdToActivate && modeIdToDeactivate) {
                switchModes(masterPagePointer, modeIdToDeactivate, modeIdToActivate)
            } else if (modeIdToActivate) {
                activateMode(masterPagePointer, modeIdToActivate)
            } else if (modeIdToDeactivate) {
                deactivateMode(masterPagePointer, modeIdToDeactivate)
            }
        }
    }

    const resetAllActiveModes = () => {
        const allActiveModes = getAllActiveModeIds()
        _.forEach(allActiveModes, (state, modeId) => deactivateModeById(modeId))
    }

    return {
        getAllActiveModeIds,
        updateActiveSOSPModes,
        switchModes,
        activateMode,
        deactivateMode,
        getViewMode,
        resetAllActiveModes,
        createDisplayedNode: _.noop
    }
}

const functionLibrary = {
    getModesPrivateApis
}

export {name, functionLibrary}
