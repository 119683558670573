import {getPointer} from '../../utils/pointerUtils'
const name = 'RenderRealTimeConfigAspect'
const pointerType = 'renderRealTimeConfigPointer'

const createPointersMethods = () => {
    const getRenderRealtimeConfigItem = item => getPointer(item, pointerType)
    const getIsCompHiddenPointer = (compId, viewMode) => getPointer(['compsToHide', viewMode, compId].join('_'), pointerType)
    return {
        general: {
            getRenderRealtimeConfigItem,
            getIsCompHiddenPointer
        }
    }
}

const functionLibrary = {
    pointer_type_renderRealTimeConfig: createPointersMethods
}

const defaultModel = {}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType
}
