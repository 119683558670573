export const name = 'RenderFlags'

export const defaultModel = {
    allowSiteOverflow: true,
    componentPreviewStates: {},
    componentViewMode: 'preview',
    hideSiteBackground: false,
    isComponentVisible: {},
    shouldBlockGoogleMapInteraction: false,
    shouldResetTinyMenuZIndex: false,
    shouldResetSlideShowNextPrevButtonsPosition: false,
    shouldResetSubscribeFormMinMaxWidth: false,
    shouldUpdateJsonFromMeasureMap: false,
    showControllers: false,
    ignoreComponentsCollapsedProperty: [],
    ignoreComponentsHiddenProperty: [],
    isPlayingAllowed: true,
    isZoomAllowed: true,
    isSocialInteractionAllowed: true,
    isExternalNavigationAllowed: true,
    isBackToTopButtonAllowed: true,
    isWixAdsAllowed: true,
    isSlideShowGalleryClickAllowed: true,
    isTinyMenuOpenAllowed: true,
    renderFixedPositionContainers: true,
    shouldResetGalleryToOriginalState: false,
    shouldResetComponent: true,
    siteScale: 1,
    allowShowingFixedComponents: true,
    renderFixedPositionBackgrounds: true,
    showHiddenComponents: false,
    initWixCode: true,
    isComponentTransitionAllowed: true,
    extraSiteHeight: 0,
    shouldRenderTPAsIframe: true,
    layoutMechanism: null
}
