/*eslint no-unused-vars:0*/
import {getPointer} from '../../utils/pointerUtils'
import _ from 'lodash'

const name = 'PagePointerAspect'
const pointerType = 'page'

const getByPagePointer = (pagesDataFromFull, pointerId, innerPath) => {
    if (pointerId === 'all') {
        return pagesDataFromFull
    }

    const page = pagesDataFromFull[pointerId]

    if (innerPath) {
        return _.get(page, innerPath)
    }

    return page
}

const createPointersMethods = ({allPages}) => {
    const isExists = pageId => !!allPages[pageId]
    const getAllPagesPointer = () => getPointer('all', pointerType)
    const getResolvedDataMapsPointer = () => getPointer('resolvedDataMaps', pointerType)

    return {
        page: {
            getAllPagesPointer,
            getResolvedDataMapsPointer,
            isExists
        }
    }
}

const functionLibrary = {
    pointer_type_pagePointer: createPointersMethods,
    getter_pagePointer: getByPagePointer
}

const defaultModel = {}

export {name, functionLibrary, defaultModel, pointerType}
