// @ts-check
/*eslint fp/no-proxy:0, no-unused-vars:0, no-unreachable:0*/
const TARGET = Symbol('TARGET')

function getErrorMsg(key, operation) {
    return `Attempt to ${operation} key "${key}" on immutable object. Clone using deepClone() and then modify.`
}

const isObject = obj => typeof obj === 'object' && obj !== null

const proxyHandler = {

    get(target, key, receiver) {
        if (key === TARGET) {
            return target
        }
        const obj = target[key]
        if (isObject(obj)) {
            return new Proxy(obj, proxyHandler)
        }
        return obj
    },

    set(target, key, value) {
        throw new Error(getErrorMsg(key, 'set'))
        return false
    },

    deleteProperty(target, key) {
        if (key in target) {
            throw new Error(getErrorMsg(key, 'delete'))
        }
        return false
    },

    defineProperty(target, key) {
        throw new Error(getErrorMsg(key, 'define'))
        return false
    }
}

const proxyCache = {
    rawObject: undefined,
    proxy: undefined
}

// Returns a proxy that provides read only access to the underlying nested object
// An attempt to modify the underlying object will result in an exception being thrown
function createImmutableProxy(obj) {
    if (isObject(obj)) {
        // The the same object is often requested more than once in succession from the Dal, so this
        // optimisation reduces the number of proxies that are created
        if (proxyCache.rawObject === obj) {
            return proxyCache.proxy
        }
        // if not already a proxy
        if (!obj[TARGET]) {
            proxyCache.rawObject = obj
            proxyCache.proxy = new Proxy(obj, proxyHandler)
            return proxyCache.proxy
        }
    }
    return obj
}

// Returns a deep clone of a plain data object
// The argument and any of its child nested objects can be immutable proxies
// The returned clone will not contain any proxies
function deepCloneDataObject(obj) {
    if (Array.isArray(obj)) {
        const copy = obj.slice(0)
        for (let i = 0; i < copy.length; ++i) {
            const val = obj[i]
            if (typeof val === 'object' && val !== null) {
                copy[i] = deepCloneDataObject(val)
            }
        }
        return copy
    }
    const copy = {...obj}
    // eslint-disable-next-line guard-for-in
    for (const key in obj) {
        const val = obj[key]
        if (typeof val === 'object' && val !== null) {
            copy[key] = deepCloneDataObject(val)
        }
    }
    return copy
}

function deepClone(obj) {
    if (isObject(obj)) {
        // This is a performance optimization for proxies to prevent the entire nested object being proxied.
        // It's typically only needed for the root object of a proxy so we do it here
        // instead of in the recursive clone function for every nested object
        const proxyTarget = obj[TARGET]
        return deepCloneDataObject(proxyTarget || obj)
    }
    return obj
}

function isProxy(obj) {
    return isObject(obj) && !!obj[TARGET]
}

module.exports = {
    createImmutableProxy,
    deepClone
}

