export const defaultModel = {}
let $ // eslint-disable-line santa/no-module-state
let _ // eslint-disable-line santa/no-module-state

class SiteScrollingBlockerImplementation {
    constructor(window, browserFlags, exitFullScreenMode) {
        this.window = window

        this._blockersList = []
        this.blockListeners = []
        this._scrollCorrection = undefined

        this.fixOverflowScrollEdges = browserFlags.fixOverflowScrollEdges
        this.exitFullScreenMode = exitFullScreenMode
    }

    setSiteScrollingBlocked(blocker, value) {
        const isAlreadyBlocked = _.head(this._blockersList)

        this._blockersList = value ?
            _(this._blockersList).concat(blocker).uniq().value() :
            _.without(this._blockersList, blocker)

        const shouldBeBlocked = _.head(this._blockersList)

        if (isAlreadyBlocked === shouldBeBlocked) {
            return
        }

        if (shouldBeBlocked) {
            this.blockSiteScrolling(blocker)
        } else {
            this.unblockSiteScrolling(blocker)
        }
    }

    setSiteScrollingBlockedForModal(blocker, value, isiOS) {
        if (!isiOS) {
            return this.setSiteScrollingBlocked(blocker, value)
        }

        const siteContainerElement = $('#SITE_CONTAINER')
        if (!value && siteContainerElement.css('overflow') === 'hidden') {
            return siteContainerElement.css({height: 'auto', overflow: 'auto'})
        }

        return null
    }

    isSiteScrollingBlocked() {
        return !!_.size(this._blockersList)
    }


    registerScrollingLayer(layer) {
        if (!this.fixOverflowScrollEdges) {
            return
        }

        layer.addEventListener('scroll', () => {
            layer.scrollTop = _.clamp(layer.scrollTop, 1, layer.scrollHeight - layer.clientHeight - 1)
        })
    }

    registerScrollBlockedListener(listener) {
        this.blockListeners.push(listener)
    }

    onHeightChanged(isiOS, height) {
        if (isiOS) {
            this.exitFullScreenMode() //was : this._siteAPI.exitFullScreenMode()
            return $('#SITE_CONTAINER').css({height, overflow: 'hidden'})
        }

        return null
    }

    getDeviceSpecificProperties(isMobile, isiOS, height) {
        if (isMobile && isiOS && height > 0) {
            return {
                '': {
                    style: {
                        position: 'absolute',
                        height
                    }
                },
                dialog: {
                    style: {
                        position: 'absolute'
                    }
                },
                iframe: {
                    style: {
                        height
                    }
                }
            }
        }

        return {}
    }

    saveScrollPosition() {
        this._scrollCorrection = this.window.scrollY
    }

    restoreScrollPosition() {
        this.window.scrollTo(0, this._scrollCorrection)
    }

    blockSiteScrolling(blocker) {
        const notifyBlocked = () => listener => listener.handleBlockedBy(blocker)

        this.saveScrollPosition()
        $('html')
            .css({marginTop: `${-Math.max(0.5, this.window.scrollY)}px`})
            .addClass('blockSiteScrolling')

        this.blockListeners.forEach(notifyBlocked())
    }


    unblockSiteScrolling(blocker) {
        const notifyUnblocked = () => listener => listener.handleUnblockedBy(blocker)

        $('html')
            .removeClass('blockSiteScrolling')
            .css({marginTop: ''})

        this.restoreScrollPosition()
        this.blockListeners.forEach(notifyUnblocked())
    }
}


let siteScrollingBlockerObject = null // eslint-disable-line santa/no-module-state
export const functionLibrary = {
    siteScrollingBlocker: (window, browserFlags, exitFullScreenMode) => {
        if (!siteScrollingBlockerObject && window) {
            siteScrollingBlockerObject = new SiteScrollingBlockerImplementation(window, browserFlags, exitFullScreenMode)
        }

        return siteScrollingBlockerObject
    },
    isSiteScrollingBlocked: () => siteScrollingBlockerObject.isSiteScrollingBlocked()
}

export function init(/*carmiInstance, {eventsManager}*/) {
    $ = require('zepto')
    _ = require('lodash')
}
