import {getPointer} from '../../utils/pointerUtils'

const name = 'CustomElementsPointerAspect'
const pointerType = 'customElementsPointer'
const cacheKillersStorePath = 'cacheKillers'

const functionLibrary = {
    pointer_type_customElements: () => ({
        customElements: {
            getCacheKillerCounter: fileId => getPointer(cacheKillersStorePath, pointerType, [fileId])
        }
    })
}

const defaultModel = {
    [cacheKillersStorePath]: {}
}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType,
    cacheKillersStorePath
}
