const name = 'PreviewVisibilityAspect'
import _ from 'lodash'

const getCompVisibilityClasses = ({isGhostHidden, isGhostCollapsed}) => {
    const classNames = []
    isGhostHidden ? classNames.push('hidden-comp-ghost-mode') : _.noop() //eslint-disable-line no-unused-expressions
    isGhostCollapsed ? classNames.push('collapsed-comp-mode') : _.noop() //eslint-disable-line no-unused-expressions
    return classNames.join(' ')
}

const functionLibrary = {
    getCompVisibilityClasses
}
export {name, functionLibrary}
