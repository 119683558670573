import _ from 'lodash'
import {createAwaiter} from '../../../utils/carmiAwaiter'

const name = 'privateApiCommonExtension'

const stopAnimations = ({
    animationManager,
    componentsIdsForAnimations,
    clearCompsWithRuntimeAnimations,
    elementsToClear,
    setAnimationsEnabled
}) => {
    animationManager.stopAnimations(undefined, {skipPersistent: false})
    clearCompsWithRuntimeAnimations(elementsToClear)
    componentsIdsForAnimations.forEach(compId => animationManager.preventPendingScreenInAnimation(compId))
    setAnimationsEnabled(false)
}

const resetAnimationManagerState = animationManager => {
    animationManager.resetLocalAndSessionStates()
}

const startAnimations = ({setAnimationsEnabled}) => {
    setAnimationsEnabled(true)
}

const dataRequirementAwaiter = createAwaiter()

const getCommonPrivateApis = (siteApiData, dal, pointers) => {
    const isQaMode = () => {
        const query = siteApiData.viewer.currentUrl.query
        return _.has(query, 'isqa') && query.isqa !== 'false'
    }

    const reportBI = siteApiData.common.reportBI
    const getAllRenderedRootIds = () => siteApiData.common.allRenderedRootIds
    const getCurrentUrl = () => siteApiData.viewer.currentUrl
    const registerNavigationComplete = fn => siteApiData.registerNavigationComplete(fn)
    const registerNavigationError = fn => siteApiData.registerNavigationError(fn)
    const getPrimaryPageId = () => siteApiData.common.primaryPageId
    const getMetasiteId = () => siteApiData.common.metaSiteId
    const getQueryParam = paramName => _.get(siteApiData.viewer.currentUrl, ['query', paramName])
    const isDebugModeFlag = () => _.get(siteApiData.viewer.currentUrl, ['query', 'debug'])
    const isDebugMode = () => !!isDebugModeFlag()
    const hasDebugQueryParam = () => !!isDebugModeFlag()
    const getRenderFlag = flagName => dal.get(pointers.general.getRenderFlag(flagName))
    const setRenderRealTimeConfigItem = (itemName, content) => dal.set(
        pointers.general.getRenderRealtimeConfigItem(itemName),
        content)

    const setPreviewTooltipCallback = _.partial(setRenderRealTimeConfigItem, 'previewTooltipCallback')
    const setErrorPagesPopUp = _.partial(setRenderRealTimeConfigItem, 'errorPagesPopUp')
    const setPremiumNavigationHandler = _.partial(setRenderRealTimeConfigItem, 'premiumNavigationHandler')
    const isPageLandingPage = pageId => !!siteApiData.common.landingPages[pageId]
    const getCurrentUrlPageId = () => siteApiData.viewer.currentUrlPageId
    const getMainPageId = () => siteApiData.common.mainPageId
    const getSiteId = () => siteApiData.common.siteId
    const getClientSpecMap = () => siteApiData.common.clientSpecMap
    const getAppInstance = siteApiData.common.getAppInstance
    const getFocusedRootId = () => siteApiData.viewer.focusedRootId
    const registerToNotifyApplicationRequestFromViewerWorker = siteApiData.viewer.registerToNotifyApplicationRequestFromViewerWorker
    const registerToAppInstanceUpdate = siteApiData.viewer.registerToAppInstanceUpdate
    const reloadAppsContainer = siteApiData.viewer.reloadAppsContainer
    const updateBiData = siteApiData.viewer.updateBiData
    const getViewerLoadedPagesIds = () => siteApiData.viewer.loadedPageIds
    const getRootNavigationInfo = pageId => {
        const navigationInfos = siteApiData.viewer.navigationInfos
        const page = pointers.page.isExists(pageId)

        if (page) {
            return navigationInfos[pageId]
        }

        return navigationInfos.primaryPage
    }
    const getMasterPageLayoutSettings = () => siteApiData.layout.masterPageLayoutSettings

    const getActiveModes = () => {
        const pointer = pointers.activeModes.getAllActiveModes()
        return dal.get(pointer)
    }

    const handleBehavior = (behavior, {callback}) => {
        const behaviorWithCallback = {...behavior, callback}
        siteApiData.handleBehavior(behaviorWithCallback)
    }

    const isExperimentOpen = experimentName => siteApiData.common.runningExperiments[experimentName] === 'new'
    const hasPendingFonts = () => siteApiData.fonts.hasPendingFonts

    const getScreenSize = () => siteApiData.common.screenSize
    const getSiteX = () => siteApiData.common.siteX

    const resetBehaviorsRegistration = () => {
        const manager = siteApiData.animations.animationManager
        if (manager !== null) {
            resetAnimationManagerState(manager)
        }
    }

    const isComponentRenderedOnSite = compId => !!siteApiData.common.compsRefs[compId]

    const getRuntimeDal = () => ({
        getCompState: compId => siteApiData.common.runtimeState[compId] || {},
        getCompData: compId => siteApiData.common.runtimeDal.getCompData(compId),
        getCompProps: compId => siteApiData.common.runtimeDal.getCompProps(compId),
        getCompDesign: compId => siteApiData.common.runtimeDal.getCompDesign(compId),
        getAllCompsRuntimeData: () => siteApiData.common.runtimeData,
        resetRuntimeForComp: compId => siteApiData.common.runtimeDal.resetRuntimeForComp(compId)
    })

    const isDuringNavigationInteraction = () => siteApiData.viewer.isDuringNavigationInteraction

    const {scrollToComponent} = siteApiData.common

    const {isSiteScrollingBlocked} = siteApiData.common

    const DOCUMENT_SERVICES_BI_SRC = 40
    const BI_ERROR_PARAMS = ['p1', 'p2', 'p3', 'p4']
    const reportBIError = (errorCode, errorName, ...additionalParams) => {
        const params = [siteApiData.common.editorSessionId].concat(additionalParams)
        reportBI({
            errorCode,
            errorName,
            severity: 'error',
            src: DOCUMENT_SERVICES_BI_SRC,
            params: _.mapKeys(BI_ERROR_PARAMS)
        }, _.zipObject(BI_ERROR_PARAMS, params))
    }

    return {
        getAllRenderedRootIds,
        isComponentRenderedOnSite,
        getPrimaryPageId,
        getMetasiteId,
        getQueryParam,
        isDebugMode,
        hasDebugQueryParam,
        getRenderFlag,
        setPreviewTooltipCallback,
        setErrorPagesPopUp,
        setPremiumNavigationHandler,
        isPageLandingPage,
        getCurrentUrlPageId,
        getMainPageId,
        getFocusedRootId,
        getRootNavigationInfo,
        getClientSpecMap,
        getAppInstance,
        getCurrentUrl,
        isQaMode,
        getSiteId,
        registerToNotifyApplicationRequestFromViewerWorker,
        reloadAppsContainer,
        registerToAppInstanceUpdate,
        registerNavigationComplete,
        registerNavigationError,
        isDuringNavigationInteraction,
        getMasterPageLayoutSettings,
        getActiveModes,
        handleBehavior,
        getScreenSize,
        isExperimentOpen,
        hasPendingFonts,
        getSiteX,
        resetBehaviorsRegistration,
        reportBI,
        reportBIError,
        reloadPageAnimations: () => startAnimations(siteApiData.animations),
        stopAndClearAllAnimations: () => stopAnimations(siteApiData.animations),
        updateBiData,
        flushDsBeforeLoadMessagesQueue: cb => {
            if (typeof window !== 'undefined' && window.dsBeforeLoadMessageBuffer) {
                    window.removeEventListener('message', window.addDsBeforeLoadMessage, false)
                    _.forEach(window.dsBeforeLoadMessageBuffer, cb)
                    window.dsBeforeLoadMessageBuffer = []
            }
        },
        /**
         * These methods are deprecated
         *
         * They were used to enable, disable and execute animations when switching between preview and editor modes.
         * They are no longer needed for that functionality, which now works through carmi transitions.
         * If you need these methods for other purposes, or if the aforementioned functionality isn't working correctly
         * please contact the document services team.
         */
        executeAction: _.noop,
        enableAction: _.noop,
        disableAction: _.noop,
        getViewerLoadedPagesIds,
        getRuntimeDal,
        isSiteScrollingBlocked,
        scrollToComponent,
        waitForDataRequirements: () => dataRequirementAwaiter.wait(state => !!state)
    }
}

const functionLibrary = {
    disableAnimationsInEditor: (mode, setAnimationsEnabled) => {
        if (mode === 'editor') {
            setAnimationsEnabled(false)
        }
    },
    handleDataRequirementStateChange: dataRequirementAwaiter.onChange,
    getCommonPrivateApis
}

export {name, functionLibrary}
