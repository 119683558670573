import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

const name = 'platformExtension'

export const defaultModel = {
    appInstanceUpdateCb: () => {}
}

const functionLibrary = {
    onInstanceChanged: (appInstanceMap, triggerAppInstanceUpdate) => {
        triggerAppInstanceUpdate(appInstanceMap)
    },
    registerToAppInstanceUpdate: withActions(({setAppInstanceUpdateCb}, cb) => {
        setAppInstanceUpdateCb(cb)
    }),
    triggerAppInstanceUpdate: (getAppInstanceUpdateCb, appInstanceMap) => {
        getAppInstanceUpdateCb()(appInstanceMap)
    }
}

export {name, functionLibrary}
