import _ from 'lodash'

const name = 'privateApiLivePreviewExtension'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {getAllowedApps} from './appFilters'

const localAPI = {
    requests: [],
    debounceTime: 500
}

const aggregator = (agg, item) => {
    agg.appsToRefresh = _.compact(_.sortedUniq(_.concat(_.get(agg, 'appsToRefresh', []), _.get(item, 'apps'))))
    agg.restartWorker = agg.restartWorker || _.get(item, 'restartWorker')
    agg.resetRuntime = agg.resetRuntime || _.get(item, 'resetRuntime')
    agg.runUserCode = agg.runUserCode || _.get(item, 'runUserCode')
    agg.skipAppsCheck = agg.skipAppsCheck || _.get(item, 'skipAppsCheck')
    agg.sendLoad = agg.sendLoad || _.get(item, 'sendLoad')
    agg.sendInitAndStart = agg.sendInitAndStart || _.get(item, 'sendInitAndStart')
    agg.compsIdsToReset = agg.compsIdsToReset || _.get(item, 'compsIdsToReset')
    agg.shouldFetchData = agg.shouldFetchData || _.get(item, 'shouldFetchData') || false
    return agg
}

const restartWorkerIfNeeded = ({restartWorker}, siteApiData) => {
    if (restartWorker) {
        siteApiData.viewer.reloadAppsContainer()
    }
}

const sendLoadMessageIfNeeded = ({sendLoad}, contextsMap, siteApiData) => {
    if (sendLoad) {
        _.forEach(contextsMap, pageId => {
            siteApiData.livePreview.sendMessageToWorker('reset_editor_worker_queue_to_wait_for_load', {contextId: pageId})
        })
        siteApiData.livePreview.loadAppsContainer()
    }
}

const sentInitAndStartMessagesIfNeeded = ({sendInitAndStart}, siteApiData) => {
    if (sendInitAndStart) {
        siteApiData.livePreview.initAndStartAppsContainer()
    }
}

const resetRuntimeOverridesIfNeeded = ({resetRuntime, compsIdsToReset}, siteApiData) => {
    if (resetRuntime) {
        if (compsIdsToReset) {
            _.forEach(compsIdsToReset, compId => siteApiData.common.runtimeDal.resetRuntimeForComp(compId))
        } else {
            siteApiData.livePreview.resetRuntimeOverride()
        }
    }
}

const removeWorkerReadyStateIfNeeded = ({sendInitAndStart, sendLoad}, contextsMap, {setIsReady, setDidStart}) => {
    if (sendInitAndStart || sendLoad) {
        _.forEach(contextsMap, (pageId, ctxId) => {
            setIsReady(ctxId, false)
            setDidStart(ctxId, false)
        })
    }
}

const executeRefreshRequest = (aspectActions, siteApiData) => {
    if (!siteApiData.livePreview.isLivePreviewOpen) {
        return
    }
    const options = _.reduce(localAPI.requests, aggregator, {})
    const {appsToRefresh, skipAppsCheck, restartWorker, shouldFetchData} = options
    const contextsMap = siteApiData.livePreview.currentContexts
    const contextsArr = _.values(contextsMap)
    const appsWithViewerScript = _.map(_.values(siteApiData.livePreview.appsWithViewerScript), app => _.get(app, 'appDefinitionId'))
    const appIdsToRefresh = appsWithViewerScript.filter(appDefinitionId => appsToRefresh.includes(appDefinitionId))

    aspectActions.setLivePreviewAppsToRefresh(appsToRefresh)
    aspectActions.setLivePreviewExtraOptionsForInitMessage('shouldFetchData', shouldFetchData)


    if (skipAppsCheck || !_.isEmpty(contextsArr) && !_.isEmpty(appIdsToRefresh)) {
        if (restartWorker) {
            restartWorkerIfNeeded(options, siteApiData)
        } else {
            removeWorkerReadyStateIfNeeded(options, contextsMap, aspectActions)
            resetRuntimeOverridesIfNeeded(options, siteApiData)
            sendLoadMessageIfNeeded(options, contextsMap, siteApiData)
            sentInitAndStartMessagesIfNeeded(options, siteApiData)
        }
    }
    localAPI.requests = []
}

const debounceRefreshRequest = _.debounce(executeRefreshRequest, localAPI.debounceTime)

const handleRefreshRequest = (aspectActions, addedOptions, siteApiData) => {
    localAPI.requests.push(addedOptions)
    if (_.get(addedOptions, 'immediate')) {
        executeRefreshRequest(aspectActions, siteApiData)
    } else {
        debounceRefreshRequest(aspectActions, siteApiData)
    }
}

const getLivePreviewPrivateApis = withActions((aspectActions, siteApiData) => ({
    refreshAppsInCurrentPage: effectiveOptions => handleRefreshRequest(aspectActions, effectiveOptions, siteApiData),
    getAllowedApps: source => getAllowedApps(siteApiData, source),
    isLivePreviewOpen: siteApiData.livePreview.isLivePreviewOpen
}))

const executeOnPreview = withActions((aspectActions, siteApiData) => {
    if (localAPI.requests.length) {
        executeRefreshRequest(aspectActions, siteApiData)
        debounceRefreshRequest.cancel()
    }
})

const functionLibrary = {
    executeOnPreview,
    getLivePreviewPrivateApis
}

export {name, functionLibrary}
