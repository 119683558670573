const name = 'privateApiPagesExtension'

const getPagesPrivateApis = siteApiData => {
    const scrollToAnchor = (anchorDataId, progressCallback) => {
        siteApiData.anchors.scrollToAnchor(anchorDataId, progressCallback)
    }
    const isPopupOpened = () => Boolean(siteApiData.pages.currentPopupId)
    const getPublicBaseUrl = () => siteApiData.pages.publicBaseUrl
    const getCurrentPopupId = () => siteApiData.pages.currentPopupId

    const getPageUrl = (pageInfo, urlFormat, baseUrl) => {
        const actualBaseUrl = baseUrl || getPublicBaseUrl()
        const pageId = pageInfo.pageId
        const mainPageId = siteApiData.common.mainPageId

        if (!pageId || pageId === mainPageId) {
            return actualBaseUrl
        }

        const pageIdToSeoUri = siteApiData.pages.pageIdToSeoUri
        const pageSeoUri = pageIdToSeoUri[pageId]

        //Assuming urlFormat is always slash at this point
        return `${actualBaseUrl}/${pageSeoUri}`
    }

    //Only called in the editor context, where ads are disabled
    const getPageMargins = () => ({
        bottom: 0,
        top: 0
    })

    return {
        scrollToAnchor,
        getCurrentPopupId,
        getPageUrl,
        isPopupOpened,
        getPublicBaseUrl,
        getPageMargins
    }
}

const functionLibrary = {
    getPagesPrivateApis
}

export {name, functionLibrary}
