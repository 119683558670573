import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {siteConstants} from 'santa-core-utils'

const name = 'privateApiPlatformExtension'

const getPlatformPrivateApis = withActions((aspectActions, siteApiData) => {
    const handleDsTpaHandler = ({msg, addCallback, callback}) => {
        if (callback && addCallback) {
            addCallback(msg.callId, callback)
        }
        msg.source = window
        const UNIQUE_ID_PRFIX = 'tpa-mgs-ds-'
        msg.id = _.uniqueId(UNIQUE_ID_PRFIX)
        aspectActions.setMessage(msg.id, msg)
    }

    const getComponentsByPageIdForWixCode = pageId => {
        let compsToExclude = [siteConstants.SITE_PAGES_ID, siteConstants.PAGES_CONTAINER_ID, siteConstants.MASTER_PAGE_ID, siteConstants.SITE_BACKGROUND_ID]
        const componentsInPage = siteApiData.platform.allComponents[pageId]
        const isApplicationStudio = _.get(siteApiData.viewer.currentUrl, ['query', 'applicationStudio'])
        if (isApplicationStudio) {
            compsToExclude = compsToExclude.concat([siteConstants.HEADER_ID, siteConstants.FOOTER_ID, pageId])
        }

        return _.omit(componentsInPage, compsToExclude)
    }

    const triggerAppStudioWidgetOnPropsChanged = (contextId, oldProps, newProps) => {
        siteApiData.platform.sendMessageToWorker('trigger_app_studio_widget_on_props_changed', {contextId, oldProps, newProps})
    }

    return {
        handleDsTpaHandler,
        getComponentsByPageIdForWixCode,
        triggerAppStudioWidgetOnPropsChanged
    }
})

const reloadIframeOnWixCodeProvision = reloadFunc => reloadFunc()


const functionLibrary = {
    reloadIframeOnWixCodeProvision,
    getPlatformPrivateApis
}

export {name, functionLibrary}
