import _ from 'lodash'
import {getPointer} from '../../utils/pointerUtils'
const name = 'RuntimePointerAspect'
const pointerType = 'runtime'

const createPointersMethods = () => {
    const getTextRuntimeOverallBorders = () => getPointer('runtimeOverallBorders', pointerType)
    const getWantedNavInfo = () => getPointer('wantedNavInfo', pointerType)
    const getWantedIsMobileView = () => getPointer('wantedIsMobileView', pointerType)
    
    return {
        runtime: {
            getTextRuntimeOverallBorders,
            getWantedNavInfo,
            getWantedIsMobileView
        },
        general: {
            getTextRuntimeOverallBorders
        }
    }
}

const functionLibrary = {
    pointer_type_runtimePointer: createPointersMethods,
    ds_set_mobile_view: (isMobileView, setToHostInstance, setFullLayoutNeeded) => {
        if (!_.isBoolean(isMobileView)) {
            return
        }
        setFullLayoutNeeded(true)
        setToHostInstance(isMobileView)
    }
}

const defaultModel = {
    runtimeOverallBorders: {}
}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType
}
