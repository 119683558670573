import * as renderRealTimeConfig from './renderRealTimeConfigPointer/renderRealTimeConfigPointer'
import * as renderFlagsPointer from './renderFlagsPointer/renderFlagsPointer'
import * as pagePointer from './pagePointer/pagePointer'
import * as mobilePointer from './mobilePointer/mobilePointer'
import * as runtimePointers from './runtimePointer/runtimePointer'
import * as activeModesPointer from './activeModesPointer/activeModesPointer'
import * as svgShapesPointer from './svgShapesPointer/svgShapesPointer'
import * as customElementsPointer from './customElements/customElementsPointer'
import * as layoutPointer from './layoutPointer/layoutPointer'
import * as multilingualPointer from './multilingualPointer/multilingualPointer'
import * as ghostStructurePointer from './ghostStructurePointer/ghostStructurePointer'

export const pointerTypes = [
    renderRealTimeConfig,
    renderFlagsPointer,
    renderFlagsPointer,
    pagePointer,
    mobilePointer,
    runtimePointers,
    activeModesPointer,
    svgShapesPointer,
    customElementsPointer,
    layoutPointer,
    multilingualPointer,
    ghostStructurePointer
]
