const _ = require('lodash')

const BASE_PAGE_DATA_TYPES = {
    props: 'component_properties',
    data: 'document_data',
    design: 'design_data',
    behaviors: 'behaviors_data',
    connections: 'connections_data',
    style: 'theme_data',
    mobileHints: 'mobile_hints'
}
const PAGE_DATA_TYPES = _.assign({
    anchors: 'anchors_data',
    layout: 'layout_data',
    breakpoints: 'breakpoints_data'}, BASE_PAGE_DATA_TYPES)

const MULTILINGUAL_TYPES = {
    multilingualTranslations: 'multilingualTranslations'
}

const VIEW_MODES = {
    DESKTOP: 'DESKTOP',
    MOBILE: 'MOBILE'
}

const STYLES = {
    STYLES_PER_PAGE_VER: '1.0'
}

const MASTER_PAGE_ID = 'masterPage'

const COMP_MODES_TYPES = {
    HOVER: 'HOVER',
    DEFAULT: 'DEFAULT',
    APPLICATIVE: 'APPLICATIVE',
    SHOW_ON_SOME_PAGES: 'SHOW_ON_SOME_PAGES',
    POPOVER: 'POPOVER'
}

module.exports = {
    PAGE_DATA_TYPES,
    VIEW_MODES,
    STYLES,
    MASTER_PAGE_ID,
    COMP_MODES_TYPES,
    MULTILINGUAL_TYPES
}
