import _ from 'lodash'

const name = 'privateApiMockExtension'

const wo = text => _.once(() => console.warn(text))

const warnOnce = wo('There are mocked siteAPI methods')

const getMockPrivateApis = () => {
    warnOnce()

    return {
        resetRuntimeData: () => wo('resetRuntimeData was called'),
        collectUsedStylesFromAllPages: () => wo('collectUsedStylesFromAllPages was called'),
        createPageAnchors: () => wo('createPageAnchors was called')
    }
}

const functionLibrary = {
    getMockPrivateApis
}

export {name, functionLibrary}
