import _ from 'lodash'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

const name = 'scrollTrackerAspect'

const getCompTotalScroll = (componentsInnerScroll, structure, compId) => {
    const scrollSum = ({x: x1, y: y1}, {x: x2, y: y2}) => ({x: x1 + x2, y: y1 + y2})

    const myScroll = componentsInnerScroll[compId] || {x: 0, y: 0}

    return structure[compId] && structure[compId].parent ?
        scrollSum(getCompTotalScroll(componentsInnerScroll, structure, structure[compId].parent), myScroll) :
        myScroll
}

const getAncestorsTotalScroll = (componentsInnerScroll, structure, compId) => structure[compId] ?
    getCompTotalScroll(componentsInnerScroll, structure, structure[compId].parent) :
    {x: 0, y: 0}

// eslint-disable-next-line santa/no-module-state
let onComponentsScrollListener = null
const scrollListeners = {}

const functionLibrary = {
    getScrollTrackerApi: withActions((aspectActions, siteApiData) => {
        const {setComponentInnerScroll} = aspectActions
        const {components: {structure}, scrollTracker: {componentsInnerScroll, scrollableNodes}} = siteApiData

        const scroll = (compId, x, y, {isRelative, callback}) => {
            const node = scrollableNodes[compId]
            if (!node) {
                console.error(`unable to scroll unregistered component ${compId}`)
                return
            }

            if (isRelative) {
                scrollableNodes[compId].scrollBy(x, y)
            } else {
                scrollableNodes[compId].scrollTo(x, y)
            }

            setComponentInnerScroll(compId, {x: node.scrollLeft, y: node.scrollTop})

            if (_.isFunction(callback)) {
                callback()
            }
        }

        const setOnComponentsScrollListener = callback => {
            if (_.isFunction(callback)) {
                onComponentsScrollListener = callback
            }
        }

        const getTotalScroll = compId => {
            const comp = structure[compId]
            return comp && componentsInnerScroll[comp.parent] || {x: 0, y: 0}
        }

        return {
            scroll: (compId, x, y, callback) => scroll(compId, x, y, {isRelative: false, callback}),
            scrollBy: (compId, x, y, callback) => scroll(compId, x, y, {isRelative: true, callback}),
            getTotalScroll,
            setOnComponentsScrollListener
        }
    }),

    registerToInnerScroll: withActions(({setComponentInnerScroll, setComponentScrollableNode}, compId, scrollableSelector) => {
        const scrollableNode = window.document
            .getElementById(compId)
            .querySelector(scrollableSelector)

        setComponentInnerScroll(compId, {x: scrollableNode.scrollLeft, y: scrollableNode.scrollTop})
        setComponentScrollableNode(compId, scrollableNode)

        const scrollListener = ({target: node}) => {
            setComponentInnerScroll(compId, {x: node.scrollLeft, y: node.scrollTop})

            if (onComponentsScrollListener) {
                onComponentsScrollListener(compId, {left: node.scrollLeft, top: node.scrollTop})
            }
        }

        scrollableNode.addEventListener('scroll', scrollListener)
        scrollListeners[compId] = scrollListener
    }),

    unregisterInnerScroll: withActions(({setComponentInnerScroll, setComponentScrollableNode}, scrollableNodes, compId) => {
        const scrollableNode = scrollableNodes[compId]
        if (scrollableNode) {
            _.invoke(scrollableNode, 'scrollTo', 0, 0)
            setComponentInnerScroll(compId, undefined)
            setComponentScrollableNode(compId, undefined)
            scrollableNode.removeEventListener('scroll', scrollListeners[compId])
        }
    }),

    getAncestorsTotalScroll: (componentsInnerScroll, structure, compId) => getAncestorsTotalScroll(componentsInnerScroll, structure, compId)
}

const defaultModel = {
    componentsInnerScroll: {},
    scrollableNodes: {}
}

export {
    name,
    functionLibrary,
    defaultModel
}
