import _ from 'lodash'

const name = 'privateApiTpaExtension'

const getTpaPrivateApis = siteApiData => {
    const updatePreventRefreshState = (compId, value) => {
        const comp = siteApiData.common.compsRefs[compId]
        if (comp) {
            comp.setState(value)
        }
    }
    const removeAllPopups = siteApiData.tpa.removeAllPopups
    const removeModal = siteApiData.tpa.removeModal
    const setTpaCompPreviewData = (id, data) => siteApiData.tpa.setTpaCompPreviewData(id, data)
    const getTpaCompPreviewData = id => {
        const tpaCompsPreviewData = siteApiData.tpa.getTpaCompsPreviewData()
        return _.get(tpaCompsPreviewData, [id]) || {}
    }
    const getModalCompData = siteApiData.tpa.getModalCompData
    const getPopupsCompData = siteApiData.tpa.getPopupsCompData

    return {
        updatePreventRefreshState,
        removeAllPopups,
        removeModal,
        setTpaCompPreviewData,
        getTpaCompPreviewData,
        getModalCompData,
        getPopupsCompData
    }
}

const functionLibrary = {
    getTpaPrivateApis
}

export {name, functionLibrary}
