import _ from 'lodash'

const UNIQUE_INITIAL_VALUE = {}
const isInitialized = value => value !== UNIQUE_INITIAL_VALUE
/** A helper for async operations with carmi
 *
 * Calls a predicate on each carmi value change and resolves the promise when it's true
 *
 * Returns an object with two methods:
 * onChange: A function from carmi value to void. Should be called on each carmi value change, see example below
 * wait: An async function used to await the desired carmi value, expects a predicate, see example below
 *
 * @example
 * // In coolValues.js
 * import {waitForValue} from './waitForValue'
 * (async () => {
 *     const value = await waitForValue(v => v.startsWith('cool'))
 * })()
 *
 * // In waitForValue.js
 * const awaiter = createAwaiter()
 * export const functionLibrary {
 *     onChange: awaiter.onChange
 * }
 * export const waitForValue = awaiter.wait
 *
 * // In waitForValue.carmi.js:
 * privates: {
 *     onValueChange: value.call('onChange')
 * }
 *
 * @returns {Awaiter}
 */
export const createAwaiter = () => {
    let value = UNIQUE_INITIAL_VALUE
    let callbacks = []
    return {
        onChange: carmiValue => {
            value = carmiValue
            callbacks.forEach(cb => {
                if (cb.predicate(value)) {
                    cb.resolve(value)
                    cb.done = true
                }
            })
            callbacks = _.reject(callbacks, 'done')
        },
        wait: predicate => {
            const canResolveImmediately = isInitialized(value) && predicate(value)
            if (canResolveImmediately) {
                return Promise.resolve(value)
            }
            return new Promise(resolve => {
                callbacks.push({
                    predicate,
                    resolve,
                    done: false
                })
            })
        }
    }
}
