const _ = require('lodash')

/**
 * @param {string} id
 * @param {string} type
 * @param {string[]} [innerPath]
 * @returns {Pointer}
 */
const getPointer = (id, type, innerPath) => {
    if (!id && !type) {
         console.log('illegal pointer creation !')
    }
    
    return innerPath ?
        {id, type, innerPath} :
        {id, type}
}

/**
 * @param {Pointer} pointer
 * @param {string[]} [innerPath]
 * @returns {Pointer}
 */
const getInnerPointer = (pointer, innerPath) => {
    const newPointer = getPointer(pointer.id, pointer.type)
    if (_.isString(innerPath)) {
        innerPath = innerPath.split('.')
    }
    newPointer.innerPath = pointer.innerPath ? pointer.innerPath.concat(innerPath) : innerPath
    return newPointer
}

/**
 *
 * @type {{getPointer: *, getInnerPointer: *}}
 */
module.exports = {
    getPointer,
    getInnerPointer
}
