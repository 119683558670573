import {getPointer} from '../../utils/pointerUtils'
const name = 'SvgShapesPointerAspect'
const pointerType = 'svgShapes'

const createPointersMethods = () => ({
    svgShapes: {
        getSvgShapePointer: id => getPointer(id, pointerType)
    }
})

const functionLibrary = {
    pointer_type_svgShapesPointer: createPointersMethods
}

export {
    name,
    functionLibrary,
    pointerType
}
