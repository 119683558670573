import _ from 'lodash'
import {getStateOverrideHints, STYLABLE_OVERRIDE_STATE_PREFIX, StylableEditor} from '@wixc3/stylable-panel-drivers'
import {inlineModules, addStylableDirectiveRules, inlineModulesContext, transformationPlugins} from 'stylable-santa-flatten/dist/main'
import wixUiSantaMetadata from 'wix-ui-santa/dist/statics/wix-ui-santa.metadata.json.bundle' // TODO can we import this without the dist?

const name = 'stylableSiteAPI'

export const defaultModel = {
    quickChanges: {},
    forceState: {},
    compStyleMap: {},
    themeCounter: 0
}

const SITE_PATH = '/site.st.css'

// Init stylable-editor:
// TODO: remove once we have snapshots:
const components = _.mapValues(wixUiSantaMetadata.components, comp => _.assign({}, comp, {snapshots: ['<div style="height: 60px;width:140px;"/>']}))
const config = _.assign({}, wixUiSantaMetadata, {components})

const getStylableSiteAPIs = ({setForceState, setQuickChange, setStylableEditorInstance}) => {
    const stylableEditorInstance = new StylableEditor(config, SITE_PATH)
    stylableEditorInstance.stylableDriver.writeFile(SITE_PATH, '') // init empty site.st.css
    stylableEditorInstance.stylableDriver.registerJsModules(inlineModules) // register mixins and formatters
    stylableEditorInstance.stylableDriver.registerTransformationPlugins(transformationPlugins)

    setStylableEditorInstance(stylableEditorInstance)

    // Api functions:
    const getStylableEditorInstance = () => stylableEditorInstance
    const setQuickChanges = (compId, selector, declarationMap) => setQuickChange(compId, {
        selector,
        declarationMap
    })
    const revertQuickChanges = compId => setQuickChange(compId)

    const forceState = (compId, selector) => setForceState(compId, selector)
    const revertForceState = compId => setForceState(compId)

    return {
        getStylableEditorInstance,
        setQuickChanges,
        revertQuickChanges,
        forceState,
        revertForceState
    }
}

/**
 * writes the needed files in stylableDriver based on the styleItems recieved and the mobile view mode
 * @param stylableEditor
 * @param styleItems - style items from entire (full) page
 * @param isMobileView - is the editor in mobile view mode
 * @return {Array}
 */
const updateStyleData = (stylableEditor, styleItems, isMobileView) => {
    if (!stylableEditor) {
        return []
    }
    const {stylableDriver} = stylableEditor
    _.forEach(styleItems, styleItem => {
        const stylesheetPath = `/${styleItem.id}.st.css`
        const componentStyle = _.get(styleItem, ['style', 'properties', '$st-css'], '')

        stylableDriver.writeFile(
            stylesheetPath,
            addStylableDirectiveRules(
                componentStyle,
                isMobileView,
                {
                    siteColors: true,
                    additionalJsModules: true
                }
            )
        )
    })
    return []
}

/**
 * transforms and process a component's stylable-CSS to vanilla-CSS
 * this is triggered when a component's style is changed, or the themeCounter
 * @param stylableEditor
 * @param compStyleData - new comp style data TODO change to just id
 * @return {string|boolean} raw CSS for previewExtension
 */
const transformStylableCss = (stylableEditor, compStyleData/*, themeDataCounter, isMobileView*/) => {
    if (!compStyleData) {
        return false
    }

    const {stylableDriver} = stylableEditor
    const stylesheetPath = `/${compStyleData.id}.st.css`

    const meta = stylableDriver.stylable.process(stylesheetPath)
    meta.namespace = compStyleData.id

    return stylableDriver.buildCSS({entries: [stylesheetPath], emitBuild: false})
}

// todo: use logic from stylable-santa-flatten
const getMatrix = themeData => {
    const COLOR_ROWS = 5
    const UNWANTED_PALETTE_COLORS = 11
    const paletteColors = Object.values(themeData).slice(UNWANTED_PALETTE_COLORS)
    const COLOR_COLUMNS = paletteColors.length / COLOR_ROWS
    const colorMatrix = []
    for (let i = 0; i < COLOR_ROWS; i++) {
        colorMatrix.push(paletteColors.splice(0, COLOR_COLUMNS))
    }
    return colorMatrix
}

/**
 * Set stylable site colors for entire context, themeCounter is increased to trigger a stylable transform
 * This is triggered when the theme updates
 * @param stylableEditor
 * @param themeData - new themeData to use
 * @param setThemeCounter - setter for theme counter to increase
 * @param getCount - getter for current theme counter
 */
const updateStylableTheme = (stylableEditor, themeData, setThemeCounter, getCount) => {
    if (stylableEditor && stylableEditor.stylableDriver) {
        stylableEditor.stylableDriver.getSiteVarsDriver(SITE_PATH).setSiteColors(getMatrix(themeData.color)) // TODO same as above
        setThemeCounter(getCount() + 1)
    }
}

/**
 * update the inner context for the formatters
 * @param url - url of static media from serviceTopology
 */
const initStaticMediaUrl = url => {
    inlineModulesContext.setStaticMediaUrl(url)
}

/**
 * transforms the quickChange to what the previewExtension expects
 * @param stylableEditor
 * @param sheetPath - target sheetPath
 * @param compId - target component ID
 * @param quickChange - declaration map to apply as quickChange
 * @return {undefined|{declarationMap: *, selector: *}} quickChange value ready for previewExtension
 */
const getTransformedQuickChange = (stylableEditor, sheetPath, compId, quickChange) => {
    if (stylableEditor && sheetPath) {
        const sheetDriver = stylableEditor.stylableDriver.getStylesheet(sheetPath)

        if (quickChange && sheetDriver) {
            const {selector, declarationMap} = quickChange
            const stylableSelector = sheetDriver.getTargetSelector(selector, true)
            const newSelector = `#${compId}${stylableSelector}, #${compId} ${stylableSelector}`

            return {
                selector: newSelector,
                declarationMap
            }
        }
    }
    return undefined
}

/**
 * transforms the forceState to what the previewExtension expects
 * @param stylableEditor
 * @param sheetPath - target sheetPath
 * @param compId - target component ID
 * @param selector - original selector before stylable transform
 * @return {{selector: string, stateAttribute: *}|undefined} forceState value ready for previewExtension
 */
const getTransformedForceState = (stylableEditor, sheetPath, compId, selector) => {
    if (stylableEditor && sheetPath) {
        const sheetDriver = stylableEditor.stylableDriver.getStylesheet(sheetPath)

        if (selector && sheetDriver) {
            const targetSelector = sheetDriver.getTargetSelector(selector)
            const {stateAttribute, selectorToQuery} = getStateOverrideHints(targetSelector, STYLABLE_OVERRIDE_STATE_PREFIX)

            return {
                selector: `#${compId}${selectorToQuery}, #${compId} ${selectorToQuery}`,
                stateAttribute
            }
        }
    }
    return undefined
}

const functionLibrary = {
    getTransformedForceState,
    getTransformedQuickChange,
    transformStylableCss,
    getStylableSiteAPIs,
    updateStylableTheme,
    updateStyleData,
    initStaticMediaUrl
}

export {name, functionLibrary}
