import {createAwaiter} from '../../../utils/carmiAwaiter'

export const name = 'selectiveDownloadPrivateAPI'

export const STORE_KEY = 'selectiveDownloadPrivateAPIStore'

const awaiter = createAwaiter()

const getSelectiveDownloadPrivateApis = siteApiData => ({
        downloadCompClasses: async names => {
            names.forEach(siteApiData.selectiveDownload.pushToClassesToDownload)
            await awaiter.wait(compClasses => names.every(n => n in compClasses))
            siteApiData.selectiveDownload.setClassesToDownload([])
        }
    })

export const functionLibrary = {
    handleCompClassesChange: awaiter.onChange,
    getSelectiveDownloadPrivateApis
}
export const defaultModel = {
    [STORE_KEY]: []
}
