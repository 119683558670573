const viewerManager = require('./viewerManager/viewerManager')
const siteAPI = require('./siteAPI/siteAPI')
const platform = require('./platform/platform')
const {documentToViewerTypes} = require('./documentToViewerTypes/documentToViewerTypes')
const {pointerTypes} = require('./pointerTypes/pointerTypes')
const {siteApiExtensions} = require('./siteAPI/extensions/siteApiExtensions')
const {previewPlugins} = require('./previewPlugins/previewPlugins')

module.exports = [
    viewerManager,
    siteAPI,
    platform,
    ...documentToViewerTypes,
    ...previewPlugins,
    ...pointerTypes,
    ...documentToViewerTypes,
    ...siteApiExtensions
]
