import _ from 'lodash'
import {MULTILINGUAL_TYPES} from '../../../constants/constants'

const name = 'MultilingualTranslationPointerAspect'
const pointerType = MULTILINGUAL_TYPES.multilingualTranslations

const separator = '^'
const getTranslationInfoFromKey = key => _.split(key, separator)

const setTranslationDataItem = (setter, value, key) => {
    const [, lang, itemId] = getTranslationInfoFromKey(key)  
    setter(lang, itemId, value)
}

const functionLibrary = {
    setTranslationDataItem
}

export {
    name,
    functionLibrary,
    pointerType
}
