const dataType = 'serviceTopology'

const createPointersMethods = () => {
  const getServiceTopology = () => ({id: dataType, type: dataType})
  return {
    serviceTopology: {
      getServiceTopology
    },
    general: {
      getServiceTopology
    }
  }
}

const getDocumentDataTypes = () => ({[dataType]: dataType})

const createExtension = () => ({
  createPointersMethods,
  getDocumentDataTypes
})

export {
  createExtension
}
