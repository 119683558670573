import _ from 'lodash'

const name = 'SiteAPIAspect'

const createSiteAPIBuilder = (siteApiData, extensionApiGetters) => ({dal, pointers}) =>
    _.reduce(extensionApiGetters, (all, getExtensionApi) => {
        const privateApi = getExtensionApi(siteApiData, dal, pointers)
        return _.merge(all, privateApi)
    }, {})


const functionLibrary = {
    createSiteAPIBuilder
}

const defaultModel = {}

export {
    name,
    functionLibrary,
    defaultModel
}
