import {getPointer} from '../../utils/pointerUtils'
const name = 'MobilePointerAspect'
const pointerType = 'mobile'

const createPointersMethods = () => {
    const getCommittedMobilePages = () => getPointer('committedMobilePages', pointerType)
    const getMobileStructuresPointer = () => getPointer('mobileStructures', pointerType)

    return {
        general: {
            getCommittedMobilePages,
            getMobileStructuresPointer
        }
    }
}

const functionLibrary = {
    pointer_type_mobile: createPointersMethods
}

const defaultModel = {}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType
}
