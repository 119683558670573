import _ from 'lodash'
export const name = 'privateApiLayoutExtension'
export const functionLibrary = {
    getLayoutPrivateApi: (onEvent, onEventOnce, siteApiData) => ({
        masterPageLayoutSettings: siteApiData.layout.masterPageLayoutSettings,
        isLayoutPending: () => siteApiData.layout.isLayoutPending,
        getLayoutMechanism: () => siteApiData.layout.getLayoutMechanism,
        getSiteMeasureMap: () => siteApiData.layout.measureMap,
        getBasicMeasureForComp: compId => ({
                width: _.get(siteApiData.layout.measureMap.width, compId),
                height: _.get(siteApiData.layout.measureMap.height, compId),
                y: _.get(siteApiData.layout.measureMap.top, compId),
                x: _.get(siteApiData.layout.measureMap.left, compId)
            }),
        registerToLayoutDone: handler => {
            onEvent('ds-layout-done', handler)
        },

        registerToNextLayoutDone: handler => {
            onEventOnce('ds-layout-done', handler)
        },
        setLockedCompsForEnforceAnchors: comps => {
            siteApiData.layout.setLockedCompsForEnforceAnchors(comps)
        },
        createChildrenAnchors: () => {
            // we probably don't need this anymore, it just happens in the viewer
        },
        setFullLayoutNeeded: bool => {
            siteApiData.layout.setFullLayoutNeeded(bool)
        },
        setIsGeneratingAnchors: bool => {
            siteApiData.layout.setIsGeneratingAnchors(bool)
        },
        setIsEnforcingAnchors: bool => {
            siteApiData.layout.setIsEnforcingAnchors(bool)
        }
    }),

    notifyLayoutDone: (layoutCount, emitEvent, getParams, shouldResetLayoutCounter) => {
        //This is ugly but crucial to replaceBoltStructure mechanism
        //As replaceBoltStructure reset the layoutCounter to 0  when chanaing viewMode
        //this code makes sure layoutedCompsMeasureMap will not leak at transition time
        if (layoutCount === 0 && shouldResetLayoutCounter) {
            return
        }
        //it will be better to wait for carmi recalculation end in emitEvent
        const params = getParams()
        const clonedParams = {...params, layoutedCompsMeasureMap: _.clone(params.layoutedCompsMeasureMap)}
        // _.defer(() => emitEvent('ds-layout-done', clonedParams))

        //eslint-disable-next-line consistent-return
        return Promise.resolve().then(() => emitEvent('ds-layout-done', clonedParams))
    }

}
