import {getPointer} from '../../utils/pointerUtils'
const name = 'LayoutPointerAspect'
const pointerType = 'layoutAdjustment'

const createPointersMethods = () => {
    const getLayoutAdjustmentPointer = () => getPointer('layoutAdjustment', pointerType)

    return {
        layoutAdjustment: {
            getLayoutAdjustmentPointer
        }
    }
}

const functionLibrary = {
    pointer_type_layout: createPointersMethods
}

const defaultModel = {}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType
}
