import _ from 'lodash'

const name = 'privateApiPreviewAnimationExtension'

const getPrivateApis = ({animationData}) => {
    let nextSequenceId = 0
    const activeSequences = {}
    const CLEAR_ANIMATION_PROPS = 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin'

    const getDomElement = id => animationData.windowObject.document.getElementById(id)

    const getDomElements = ids => _(ids).toArray().map(getDomElement).compact().value()

    const createSequenceWrapper = (sequence, elements, onComplete = _.noop) => {
        const sequenceId = `seq_${nextSequenceId++}`
        activeSequences[sequenceId] = animationData.animator.sequence({
            callbacks: {
                onComplete: () => {
                    delete activeSequences[sequenceId]
                    onComplete()
                }
            }
        })
            .add(sequence)
            .add(animationData.animator.animate('BaseClear', elements, 0, 0, {
                props: CLEAR_ANIMATION_PROPS,
                immediateRender: false
            }))
            .get()

        return sequenceId
    }

    const previewAnimation = (compId, pageId, animationDef, onComplete = _.noop) => {
        const element = getDomElement(compId)
        if (!element) {
            return null
        }

        const sequence = animationData.animator.animate(animationDef.name, element, animationDef.duration, animationDef.delay, {
            ...animationDef.params
        })

        return createSequenceWrapper(sequence, [element], onComplete)
    }

    const previewTransition = (srcIds, targetIds, pageId, animationDef, onComplete = _.noop) => {
        const srcElements = getDomElements(srcIds)
        const targetElements = getDomElements(targetIds)
        if (_.isEmpty(srcElements) && _.isEmpty(targetElements)) {
            return null
        }

        const sequence = animationData.animator.transition(animationDef.name, srcElements, targetElements, animationDef.duration, animationDef.delay, {
            ...animationDef.params
        })

        return createSequenceWrapper(sequence, srcElements.concat(targetElements), onComplete)
    }

    const stopPreviewAnimation = sequenceId => {
        const sequence = activeSequences[sequenceId]
        if (sequence) {
            delete activeSequences[sequenceId]
            animationData.animator.kill(sequence, 1)
        }
    }

    const getSiteAnimationsService = () => animationData.animator

    return {
        previewAnimation,
        previewTransition,
        stopPreviewAnimation,
        getSiteAnimationsService
    }
}

const functionLibrary = {
    getPreviewAnimationPrivateApis: getPrivateApis
}

export {name, functionLibrary}
