import {Graph, FunctionLibrary, GraphBase} from 'carmi'
import {RootAspectPublicAPI} from './aspects/RootAspectAPI'
import {RendererModelAspectPublicAPI} from './aspects/RendererModelAspectAPI'
import {WindowObjectAspectPublicAPI} from './aspects/WindowObjectAspectAPI'
import {WindowScrollAspectPublicAPI} from './aspects/WindowScrollAspectAPI'
import {BrowserAspectPublicAPI} from './aspects/BrowserAspectAPI'
import {AnimationAspectAPI} from './aspects/AnimationAspectAPI'
import {PublicModelAspectAPI} from './aspects/PublicModelAspectAPI'
import {ExperimentAspectPublicAPI} from './aspects/ExperimentAspectAPI'
import {ExternalAspectPublicAPI} from './aspects/ExternalAspectAPI'
import {ServiceTopologyAspectAPI} from './aspects/ServiceTopologyAspectAPI'
import {RenderFlagsAspectAPI} from './aspects/RenderFlagsAspectAPI'
import {RenderFlagsOverridesAspectAPI} from './aspects/RenderFlagsWithOverridesAspectAPI'
import {LayoutAspectAPI} from './aspects/LayoutAspectAPI'
import {ComponentsExtensionAspectAPI} from './aspects/ComponentsExtensionAspectAPI'
import {HostSpecificFontsAspectAPI} from './aspects/HostSpecificFontsAspectAPI'
import {ScreenDimensionsAspectAPI} from './aspects/ScreenDimensionsAspectAPI'
import {WarmupAspectAPI} from './aspects/WarmupAspectAPI'
import {FontsRulerAspectAPI} from './aspects/FontsRulerAspectAPI'
import {ComponentsRenderAspectAPI} from './aspects/ComponentsRenderAspectAPI'
import {ViewportAspectAPI} from './aspects/ViewportAspectAPI'
import {DeviceAspectAPI} from './aspects/DeviceAspectAPI'
import {NavigationBaseAspectAPI} from './aspects/NavigationBaseAspectAPI'
import {NavigationAspectAPI} from './aspects/NavigationAspectAPI'
import {MenuAspectAPI} from './aspects/MenuAspectAPI'
import {ParentWindowAspectAPI} from './aspects/ParentWindowAspectAPI'
import {PlatformAspectAPI} from './aspects/PlatformAspectAPI'
import {VisualFocusAspectAPI} from './aspects/VisualFocusAspectAPI'
import {StubAspectAPI} from './aspects/StubAspectAPI'
import {SiteMembersAspectAPI} from './aspects/SiteMembersAspectAPI'
import {TranslationsLoaderAspectAPI} from './aspects/TranslationsLoaderAspectAPI'
import {ModesAspectAPI} from './aspects/ModesAspectAPI'
import {TPAWidgetNativeAspectAPI} from './aspects/TPAWidgetNativeAspectAPI'
import {AppPartAspectAPI} from './aspects/AppPartAspectAPI'
import {WixappsCoreAspectAPI} from './aspects/WixappsCoreAspectAPI'
import {ListBuilderAspectAPI} from './aspects/ListBuilderAspectAPI'
import {AspectCompsContainerAPI} from './aspects/AspectCompsContainerAPI'
import {SsrModelAspectAPI} from './aspects/SsrModelAspectAPI'
import {SeoAspectAPI} from './aspects/SeoAspectAPI'
import {MultilingualAspectAPI} from './aspects/MultilingualAspectAPI'

type ToCarmiFunction<RawFunction, FunctionLib extends FunctionLibrary> =
    RawFunction extends () => infer RetType ? () => Graph<RetType, FunctionLib> :
    RawFunction extends (...args: Array<infer Args>) => infer RetType ?
    (...args: Args[]) => Graph<RetType, FunctionLib>
    : never

type ToCarmiFunctionImplementation<RawFunction, FunctionLib extends FunctionLibrary> =
    RawFunction extends () => infer RetType ? () => RetType | GraphBase<RetType> :
    RawFunction extends (...args: Array<infer Args>) => infer RetType ? (...args: Array<Graph<Args, FunctionLib>|Args>) => RetType | GraphBase<RetType>
    : never

type ToAspectInterface<RawAspect, Functions extends FunctionLibrary> = {
    [name in keyof RawAspect]: ToCarmiFunction<RawAspect[name], Functions>
}

type ToAspectImplementation<RawAspect, F extends FunctionLibrary> = {
    [name in keyof RawAspect]: ToCarmiFunctionImplementation<RawAspect[name], F>
}

declare type AspectNames = {
    [ID in keyof AspectInterfaces<{}>]: ID
}

export type AspectInterfaces<F extends FunctionLibrary> = {
    [Name in keyof PublicAPIs]: ToAspectInterface<PublicAPIs[Name], F>
}

export type AspectImplementations<F extends FunctionLibrary> = {
    [Name in keyof AspectInterfaces<{}>]: ToAspectImplementation<PublicAPIs[Name], F>
}

class PublicAPIs {
    public AspectCompsContainer: AspectCompsContainerAPI = null
    public AnimationAspect: AnimationAspectAPI = null
    public AudioAspect: {} = null
    public FontsRulerAspect: FontsRulerAspectAPI = null
    public BrowserAspect: BrowserAspectPublicAPI = null
    public ComponentsExtension: ComponentsExtensionAspectAPI = null
    public ExperimentAspect: ExperimentAspectPublicAPI = null
    public ExternalAspect: ExternalAspectPublicAPI = null
    public HostSpecificFontsAspect: HostSpecificFontsAspectAPI = null
    public Language: {} = null
    public LayoutAspect: LayoutAspectAPI = null
    public MobileAspect: {} = null
    public ModesTransitionGroup: {} = null
    public ModesAspect: ModesAspectAPI = null
    public NavigationAspect: NavigationAspectAPI = null
    public NonPageItemZoomAspect: {} = null
    public PlatformAspect: PlatformAspectAPI = null
    public VisualFocusAspect: VisualFocusAspectAPI = null
    public PublicModelAspect: PublicModelAspectAPI = null
    public RendererModelAspect: RendererModelAspectPublicAPI = null
    public RenderFlags: RenderFlagsAspectAPI = null
    public RootAspect: RootAspectPublicAPI = null
    public RuntimeAspect: any = null
    public ScreenDimensionsAspect: ScreenDimensionsAspectAPI = null
    public ServiceTopologyAspect: ServiceTopologyAspectAPI = null
    public SsrModelAspect: SsrModelAspectAPI = null
    public StubAspect: StubAspectAPI = null
    public VectorImageAspect: any = null
    public WarmupAspect: WarmupAspectAPI = null
    public WindowFocusAspect: {} = null
    public WindowKeyboardEventAspect: {} = null
    public WindowObjectAspect: WindowObjectAspectPublicAPI = null
    public WindowScrollAspect: WindowScrollAspectPublicAPI = null
    public WindowTouchEvents: {} = null
    public WelcomeScreen: never
    public BgScrubAspect: {} = null
    public ScrollScrubAspect: {} = null
    public ComponentsRenderAspect: ComponentsRenderAspectAPI = null
    public ViewportAspect: ViewportAspectAPI = null
    public DeviceAspect: DeviceAspectAPI = null
    public NavigationBaseAspect: NavigationBaseAspectAPI = null
    public MemberLoginAspect: {} = null
    public Menu: MenuAspectAPI = null
    public ParentWindowAspect: ParentWindowAspectAPI = null
    public TranslationsLoaderAspect: TranslationsLoaderAspectAPI = null
    public TPAWidgetNativeAspect: TPAWidgetNativeAspectAPI = null
    public SiteMembersAspect: SiteMembersAspectAPI = null
    public BoltSiteAspect: {} = null
    public SystemAnchorAspect: {} = null
    public ChangeRootComponent: {} = null
    public ResponsiveStylesAspect: {} = null
    public ResponsiveComponentsExtensions: {} = null
    public BreakpointsAspect: {} = null
    public NotifySiteHeightAspect: {} = null
    public WixappsCoreAspect: WixappsCoreAspectAPI = null
    public AppPartAspect: AppPartAspectAPI = null
    public ListBuilderAspect: ListBuilderAspectAPI = null
    public StylableAspect: {} = null
    public SiteMembersBaseAspect: SiteMembersAspectAPI = null
    public renderFlagsOverridesAspect: RenderFlagsOverridesAspectAPI = null
    public SeoAspect: SeoAspectAPI = null
    public multilingual: MultilingualAspectAPI = null
    public ImageXAspect: {} = null
}

const APIs = new PublicAPIs()
export const aspectNames = Object.keys(APIs).map(k => ({[k]: k})).reduce((a, v) => Object.assign(a, v), {}) as AspectNames
