import _ from 'lodash'


const BLACK_LIST = {
    appBuilderPreviewApp: '46b2ad43-5720-41d2-8436-2058979cb53f'
    // appBuilder: 'appBuilder'
    // 'My Addresses': '1505b775-e885-eb1b-b665-1e485d9bf90e',
    // 'Profile Card': '14ce28f7-7eb0-3745-22f8-074b0e2401fb',
    // 'About': '14dbef06-cc42-5583-32a7-3abd44da4908',
    // 'Followers': '14ebe801-d78a-daa9-c9e5-0286a891e46f',
    // 'Members': '14dbefd2-01b4-fb61-32a7-3abd44da4908',
    // 'Settings': '14f25dc5-6af3-5420-9568-f9c5ed98c9b1',
    // 'Notifications': '14f25924-5664-31b2-9568-f9c5ed98c9b1',
    // 'Email Marketing': '135c3d92-0fea-1f9d-2ba5-2a1dfb04297e',
    // wixCodeApp: '675bbcef-18d8-41f5-800e-131ec9e08762',
    // 'Wix Forms': '14ce1214-b278-a7e4-1373-00cebd1bef7c',
    // 'Wix Pro Gallery': '14271d6f-ba62-d045-549b-ab972ae1f70e',
    // dataBinding: 'dataBinding',
    // 'wix-code': 'wix-code',
    // metasite: '22bef345-3c5b-4c18-b782-74d4085112ff',
    // 'Members Area': '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
    // 'Members Account Info': '14cffd81-5215-0a7f-22f8-074b0e2401fb',
    // 'My Wallet': '4aebd0cb-fbdb-4da7-b5d1-d05660a30172',
    // 'Wix Stores': '1380b703-ce81-ff05-f115-39571d94dfcd',
    // 'Wix Forum': '14724f35-6794-cd1a-0244-25fd138f9242'
}

const WHITE_LIST = {
    // dataBinding: 'dataBinding',
    appBuilder: 'appBuilder'
}

const isNotBlackListedApp = (appData, blackListIds) => appData.appType !== 'Dashboard' && !_.includes(blackListIds, _.get(appData, 'appDefinitionId')) && !(_.includes(blackListIds, 'appBuilder') && (isBuilderApp(appData) || appHasBuilderWidgets(appData)))


const isWhiteListedApp = (appData, whiteListIds) => _.includes(whiteListIds, _.get(appData, 'appDefinitionId')) || _.includes(whiteListIds, 'appBuilder') && (isBuilderApp(appData) || appHasBuilderWidgets(appData))


function getAllowedApps(siteApiData, source) {
    const appsData = source === 'INIT' ? siteApiData.livePreview.initialAppsWithViewerScriptOnLoad : siteApiData.livePreview.appsWithViewerScript
    if (siteApiData.livePreview.isLivePreviewOpenForAllApps) {
        return filterAllowedApps(appsData, isNotBlackListedApp, _.values(BLACK_LIST))
    }
    if (siteApiData.livePreview.isLivePreviewOpen) {
        return filterAllowedApps(appsData, isWhiteListedApp, _.values(WHITE_LIST))
    }
    return []
}

function filterAllowedApps(appsData, filter, appIds) {
    return _(appsData)
        .filter(appData => filter(appData, appIds))
        .map('appDefinitionId')
        .compact()
        .value()
}

function isBuilderApp(appData) {
    return _.has(appData, ['appFields', 'platform', 'studio'])
}

function appHasBuilderWidgets(appData) {
    const widgets = _.values(_.get(appData, 'widgets', {}))
    return _.some(widgets, widget => _.has(widget, ['componentFields', 'appStudioFields']))
}



export {getAllowedApps}
