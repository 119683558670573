import Color from 'color'
import coreUtils from 'santa-core-utils'
import {withActions} from '../withActions'
import {parseSvgString} from './svg'
import {colorParser} from '../utils/colors'
const themeColorMatcher = /^color_([0-9]{1,2}|100)$/
const svgTagMatcher = /(<svg[^>]*)(>)/
const gTagMatcher = /(<g[^>]*)>/
const filterAttrMatcher = /filter="url\(#([^"]+)\)"/
const fillAttributeMatcher = /fill="(.*?)"/gi
const viewBoxMatcher = /viewBox="([^"]*)"/i
const preserveAspectRatioMatcher = /preserveAspectRatio="[^"]*"/i
const styleMatcher = /style="([^"]*)"/i
const styleTransformMatcher = /transform:([^;]*)/i
const svgWidthMatcher = /(?:<svg[^>]*)\s(width="[^\"]*")/i
const svgHeightMatcher = /(?:<svg[^>]*)\s(height="[^\"]*")/i
const {SVG_TYPES} = coreUtils.svgUtils


const getViewBoxString = ({x, y, width, height}) => `${x} ${y} ${width} ${height}`

const getViewBoxObject = viewBox => {
    const vieBoxArr = viewBox.split(' ')
    return {
        x: vieBoxArr[0],
        y: vieBoxArr[1],
        width: vieBoxArr[2],
        height: vieBoxArr[3]
    }
}

/**
 * Add accessibility role and label from alt text
 *
 * @param {string} svgString
 * @param {string} altText
 * @param {string} id
 * @returns {string} SVG string
 */
function addA11yFeatures(svgString, altText, id) {
    const newSvgString = svgString.replace(svgTagMatcher, '$1 role="img"$2')

    if (typeof altText === 'string') {
        const svgLabelId = `${id}-svgtitle`
        return newSvgString.replace(svgTagMatcher, `$1 aria-labelledby="${svgLabelId}"$2<title id="${svgLabelId}">${altText}</title>`)
    }

    return newSvgString
}

/**
 * Tinted SVG
 * search for all 'fill' attributes and replace with tint color'.
 *
 * @param {string} fillColor
 * @param {object} colorsMap
 * @param {string} svgString
 * @return {string} SVG string
 */
function transformToTintColors(svgString, fillColor, colorsMap) {
    const resolvedColor = colorParser.getColor(fillColor, colorsMap)
    const baseColor = new Color(resolvedColor)

    return svgString.replace(fillAttributeMatcher, (full, colorToTint) => {
        const colorObj = new Color(colorToTint)

        if (isGreyscale(colorObj)) {
            const tint = 1 - (255 - colorObj.red()) / 255 // eslint-disable-line no-mixed-operators
            const rTint = Math.floor(baseColor.red() + (255 - baseColor.red()) * tint) // eslint-disable-line no-mixed-operators
            const gTint = Math.floor(baseColor.green() + (255 - baseColor.green()) * tint) // eslint-disable-line no-mixed-operators
            const bTint = Math.floor(baseColor.blue() + (255 - baseColor.blue()) * tint) // eslint-disable-line no-mixed-operators
            const tintedColor = new Color({red: rTint, green: gTint, blue: bTint})
            // return tinted color
            return `fill="${tintedColor.hexString()}"`
        }

        // no change, return original svg color
        return `fill="${colorToTint}"`
    })
}

/**
 * calc the needed dimension for the shadow area
 * @param style
 * @param shadow
 * @returns {string}  an attributes string of filter rect dimensions
 */
function getFilterRectInPixels(style, shadow) {
    const {width, height} = style
    const {blurRadius, x, y} = shadow
    // STD to pixel ~ STD value * 3 , we are multiplying by 6 for both sides
    const blurSpread = blurRadius * 6
    const filterAttr = {
        x: Math.min(0, x) - blurSpread / 2,
        y: Math.min(0, y) - blurSpread / 2,
        width: width + blurSpread + Math.abs(x),
        height: height + blurSpread + Math.abs(y)
    }
    return `x="${filterAttr.x}" y="${filterAttr.y}" width="${filterAttr.width}" height="${filterAttr.height}"`
}

/**
 * Get svg filter string from filter templates
 * @param {string} filterId
 * @param {{x: number, y: number, blurRadius: number, color: string, opacity: number}} shadow
 * @param {{width: number, height: number}} style
 * @param {{}} colorsMap
 * @param {boolean} [shadowOnly=false] whether to merge graphic content
 * @returns {string}
 */
function getShadowFilter(filterId, shadow, style, colorsMap, shadowOnly = false) {
    const color = colorParser.getHexColor(shadow.color, colorsMap)
    const filterString = coreUtils.svgFilters.getShadow(filterId, {...shadow, color, mergeGraphic: !shadowOnly})
    return filterString.replace(/<filter /, `<filter ${getFilterRectInPixels(style, shadow)} filterUnits="userSpaceOnUse" `)
}

/**
 * Check if color is greyscale
 *
 * @param colorObj
 * @returns {boolean}
 */
function isGreyscale(colorObj) {
    return colorObj.red() === colorObj.green() &&
        colorObj.red() === colorObj.blue() &&
        colorObj.red() !== 255
}

function overrideColorCssTemplate({index, color}) {
    return `svg [data-color="${index}"] {fill: ${color};}\n`
}

/**
 * Change width/height to 100%
 *
 * @param {string} svgString SVG content
 * @param {boolean=false} [force] whether to add width/height=100% when absent
 * @return {*}
 */
function removeWidthAndHeight(svgString, force) {
    if (svgString) {
        const widthMatch = svgString.match(svgWidthMatcher)
        const heightMatch = svgString.match(svgHeightMatcher)

        if (widthMatch && widthMatch.length > 1) {
            svgString = svgString.replace(widthMatch[1], 'width="100%"')
        } else if (force) {
            svgString = svgString.replace(/<svg/, '<svg width="100%"')
        }
        if (heightMatch && heightMatch.length > 1) {
            svgString = svgString.replace(heightMatch[1], 'height="100%"')
        } else if (force) {
            svgString = svgString.replace(/<svg/, '<svg height="100%"')
        }
    }
    return svgString
}

function setPreserveAspectRatio(svgString, value) {
    const topSvg = svgString.match(svgTagMatcher)

    if (topSvg) {
        if (preserveAspectRatioMatcher.test(topSvg[0])) {
            return svgString.replace(preserveAspectRatioMatcher, `preserveAspectRatio="${value}"`)
        }

        return svgString.replace(/<svg/, `<svg preserveAspectRatio="${value}"`)
    }

    return svgString
}

function getScaledSvgViewBox(svgString, svgInfo, properties = {}, layout, strokeWidth) {
    if (svgString) {
        const {svgType, viewBox, bbox} = svgInfo
        const {preserveViewBox, displayMode} = properties

        const preserveAspectRatio = displayMode !== 'stretch'
        const preserveAspectRatioString = preserveAspectRatio ? 'xMidYMid meet' : 'none'

        svgString = setPreserveAspectRatio(svgString, preserveAspectRatioString)

        if (!svgString.match(viewBoxMatcher)) {
            svgString = svgType === SVG_TYPES.UGC ?
                svgString.replace(/<svg/, `<svg viewBox="${viewBox}"`) :
                svgString.replace(/<svg/, `<svg viewBox="${bbox}"`)
        }

        if (svgType !== SVG_TYPES.UGC && !preserveViewBox && bbox) {
            svgString = getScaledSvgWithStroke(strokeWidth, layout, bbox, preserveAspectRatio, svgString)
        }
    }

    return svgString
}

/**
 * Add flipping transform to top level SVG
 *
 * @param {string} svgString
 * @param {string} flip
 * @return {string}
 */
function getFlippedSVG(svgString, flip) {
    const topSvg = svgString.match(svgTagMatcher)
    let transform

    switch (flip) {
        case 'x':
            transform = 'scale(-1, 1)'
            break
        case 'y':
            transform = 'scale(1, -1)'
            break
        case 'xy':
            transform = 'scale(-1, -1)'
            break
    }

    if (topSvg && transform) {
        const styleMatch = topSvg[0].match(styleMatcher)
        let replacement = `$1 style="transform: ${transform};"$2`

        if (styleMatch) {
            const transformMatch = styleMatch[1].match(styleTransformMatcher)

            if (transformMatch) {
                replacement = topSvg[0].replace(
                    styleMatch[0],
                    styleMatch[0].replace(transformMatch[0], `transform: ${transform} ${transformMatch[1]}`)
                )
            } else {
                replacement = topSvg[0].replace(
                    styleMatch[0],
                    `style="transform: ${transform}; ${styleMatch[1]}"`
                )
            }
        }

        return svgString.replace(svgTagMatcher, replacement)
    }

    return svgString
}

/**
 * Adds a filter to entire content of given SVG content
 *
 * @param {string} svg SVG content
 * @param {string} filterId identifier to use for referencing the filter
 * @param {string} filterPart SVG string containing the filter
 * @return {string} transformed SVG content
 */
function applyFilterToContent(svg, filterId, filterPart) {
    const topSvg = svg.match(svgTagMatcher)
    const defsWithFilter = `<defs>${filterPart}</defs>`

    if (topSvg) {
        const filterAttr = topSvg[0].match(filterAttrMatcher)

        if (filterAttr) {
            const topG = svg.match(gTagMatcher)

            if (topG) {
                return svg.replace(topSvg[0], `${topSvg[0].replace(filterAttr[0], `filter="url(#${filterId})"`)}${defsWithFilter}`)
                    .replace(topG[0], topG[0].replace(/<g/, `<g filter="url(#${filterAttr[1]})"`))
            }

            return svg.replace(
                topSvg[0],
                `${topSvg[0].replace(
                    filterAttr[0],
                    `filter="url(#${filterId})"`
                )}${defsWithFilter}<g filter="url(#${filterAttr[1]})">`
            ).replace(/<\/svg>/, '</g></svg>') // TODO: assuming only a single </svg> in the content
        }

        return svg.replace(topSvg[0], `${topSvg[0].replace(/<svg/, `<svg filter="url(#${filterId})"`)}${defsWithFilter}`)
    }

    return svg
}

/**
 * Transforms the luminance values of the SVG content's graphics into alpha values
 *
 * @param {string} svg SVG content
 * @param {string} id identifier to ensure filter id is unique
 * @return {string} transformed SVG content
 */
function transformToLuminanceMask(svg, id) {
    const filterId = `luminance-${id}`
    const filterPart = `<filter id="${filterId}">
<feColorMatrix type="luminanceToAlpha" result="luma"/>
<feComposite in="luma" in2="SourceAlpha" operator="in"/>
</filter>`

    return applyFilterToContent(svg, filterId, filterPart)
}

/**
 * Performs transformations on the SVG string for use as mask
 *
 * @param {string} svgString
 * @param {string} flip
 * @param {'alpha'|'luminance'|'drop-shadow'} mode
 * @param {string} id used for creating a unique id on the luminance filter
 * @param {{x: number|string, y: number|string, blurRadius: number|string, color: string, opacity: number|string}} shadow drop-shadow filter config
 * @param {Object} [colorsMap] mandatory only for drop-shadow mode
 * @return {string}
 */
function getSvgContentForMask(svgString, flip, mode, id) {
    let result = getFlippedSVG(svgString, flip)

    if (mode === 'luminance') {
        result = transformToLuminanceMask(result, id)
    }

    return result
}

/**
 *
 * @param strokeWidth
 * @param layout
 * @param bbox
 * @param preserveAspectRatio
 * @param svgString
 * @returns {string}
 */
function getScaledSvgWithStroke(strokeWidth, layout, bbox, preserveAspectRatio, svgString) {
    const viewBoxValue = strokeWidth ?
        getViewBoxString(getScaledViewBox(getViewBoxObject(bbox), strokeWidth, layout, preserveAspectRatio)) :
        bbox
    const newViewBox = `viewBox="${viewBoxValue}"`

    return svgString.replace(viewBoxMatcher, newViewBox)
}


/**
 *
 * @param {{x: number, y: number, width: number, height: number}} boxBoundaries
 * @param {number} strokeWidth
 * @param {{width: number, height: number}} size, the target dom size
 * @param {boolean} maintainAspectRatio
 */
function getScaledViewBox(boxBoundaries, strokeWidth, size, maintainAspectRatio) {
    const wScale = (size.width - strokeWidth) / boxBoundaries.width
    const hScale = (size.height - strokeWidth) / boxBoundaries.height
    const aspectScale = Math.min(wScale, hScale)

    const width = size.width / (maintainAspectRatio ? aspectScale : wScale)
    const height = size.height / (maintainAspectRatio ? aspectScale : hScale)
    const x = boxBoundaries.x - (width - boxBoundaries.width) / 2 // eslint-disable-line no-mixed-operators
    const y = boxBoundaries.y - (height - boxBoundaries.height) / 2 // eslint-disable-line no-mixed-operators

    return {
        width,
        height,
        x,
        y
    }
}

export const name = 'VectorImageAspect'

export const defaultModel = {
    svgShapes: {}
}

// Runtime
export const functionLibrary = {
    getSvgContentForMask,
    scaleSvgViewBox: getScaledSvgViewBox,
    testThemeColor: val => themeColorMatcher.test(val),
    getColor: colorParser.getColor,
    addA11yFeatures,
    removeWidthAndHeight,
    transformToTintColors,
    getShadowFilter,
    handleFetchedSvg: withActions((aspectActions, svgId, data) => {
        const parsedSvg = parseSvgString(data)
        aspectActions.addSvgShape(svgId, parsedSvg)
    }),
    overrideColorCssTemplate,
    addSvgShape: withActions(({addSvgShape}, svgId, svgShape) => addSvgShape(svgId, svgShape)),
    getSvgUrl: (svgId, mediaRootUrl) => coreUtils.svgUtils.svgIdToUrl(mediaRootUrl, svgId)
}

export function init(carmiInstance, {eventsManager, initialData}) {} // eslint-disable-line
