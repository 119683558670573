const name = 'privateApiMembersExtension'

const getMembersPrivateApis = siteApiData => {
    const getMemberDetailsInPreview = (onSuccess, onError, refreshCurrentMember) => siteApiData.members.getMemberDetails(onSuccess, onError, refreshCurrentMember, true)
    return {
        getMemberDetailsInPreview
    }
}

const functionLibrary = {
    getMembersPrivateApis
}

export {name, functionLibrary}
