import _ from 'lodash'

export const name = 'privateApiViewModeExtension'

export const functionLibrary = {

    getViewModePrivateApi: (onEvent, onEventOnce, siteApiData, dal, pointers) => ({
        isMobileView: () => siteApiData.viewMode.isMobileView,

        isDuringViewModeSwitch: () => {
            const wantedIsMobile = dal.get(pointers.runtime.getWantedIsMobileView())
            return _.isBoolean(wantedIsMobile) && wantedIsMobile !== siteApiData.viewMode.isMobileView
        },
        registerToSwitchViewModeDone: handler => {
            onEvent('ds-switched-view-mode', handler)
        },

        registerToNextSwitchViewModeDone: handler => {
            onEventOnce('ds-switched-view-mode', handler)
        }
    }),

    
    notifySwitchedViewMode: (isMobile, emitEvent) => {
        //it will be better to wait for carmi recalculation end in emitEvent
        emitEvent('ds-switched-view-mode', isMobile)
    }
}
