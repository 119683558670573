import {getPointer} from '../../utils/pointerUtils'
const name = 'ghostStructureAspect'
const pointerType = 'ghostStructure'

const createPointersMethods = () => {
    const getGhostStructure = () => getPointer(pointerType, pointerType)
    return {
        general: {
            getGhostStructure
        }
    }
}

const functionLibrary = {
    pointer_type_ghostStructure: createPointersMethods
}

const defaultModel = {}

export {
    name,
    functionLibrary,
    defaultModel,
    pointerType
}
