import _ from 'lodash'
import {createDAL} from '../../viewerManagerDal/viewerManagerDal'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {getBoltFragment} from 'bolt-fragment'


const name = 'ViewerManager'

const createPrivates = initialData => ({
    pointers: initialData.pointers,
    getters: initialData.getters,
    setters: initialData.setters,
    siteAPIBuilder: initialData.siteAPIBuilder
})

const getVersion = () => {
    const boltBase = _.get(window, 'boltBase', '')
    return _.last(boltBase.split('/'))
}

/**
 * @param initialData
 * @param instance bolt instance
 * @param config
 * @returns {ViewerManager}
 */
const createViewerManager = (initialData, instance) => {
    const privates = createPrivates(initialData)
    privates.dal = createDAL(privates)
    privates.siteAPI = privates.siteAPIBuilder(privates)
    privates.siteAPI.registerNavigationError(errorInfo => {
        const errorPagesPopUpPointer = privates.pointers.general.getRenderRealtimeConfigItem('errorPagesPopUp')
        const errorPagesPopUpFn = privates.dal.get(errorPagesPopUpPointer)
        if (errorPagesPopUpFn) { errorPagesPopUpFn(errorInfo) }
    })
    
    const viewerConfig = {
        viewerName: 'bolt',
        viewerVersion: getVersion()
    }
    
    if (privates.siteAPI.isLivePreviewOpen) {
        const allowedApps = privates.siteAPI.getAllowedApps('INIT')
        instance.setLivePreviewAppsToRefresh(allowedApps)
    }
    
    return {
        dal: privates.dal,
        pointers: privates.pointers,
        siteAPI: privates.siteAPI,
        viewerConfig,
        boltInstance: instance,
        getBoltFragment
    }
}

/**
 * @param {string[]} innerPath
 * @returns {string[]}
 */
const normalizeInnerPath = innerPath => _.isUndefined(innerPath) ? [] : [].concat(innerPath)

const functionLibrary = {
    getWithInnerPath: (store, key, innerPath) => _.get(store, [key, ...normalizeInnerPath(innerPath)]),
    setWithInnerPath: (setter, value, key, innerPath, previousValue) => {
        innerPath = normalizeInnerPath(innerPath)
        if (innerPath.length > 0) {
            const nextValue = previousValue || {}
            _.set(nextValue, innerPath, value)
            setter(key, nextValue)
        } else {
            setter(key, value)
        }
    },
    registerPointers: withActions(({
        ps_registerPointer,
        ps_registerSetter,
        ps_registerGetter
    }, {
        createPointerMethods,
        getters,
        setters,
        registryName
    }) => {
        ps_registerPointer(registryName, createPointerMethods)
        _.forEach(setters, (setFunc, key) => ps_registerSetter(key, setFunc))
        _.forEach(getters, (get, key) => ps_registerGetter(key, get))
    })
}

const defaultModel = {
    pointerRegistration: {
        pointers: {},
        getters: {},
        setters: {}
    },
    documentManagerLayoutHandler: _.noop
}

const init = (instance, {initialData}) => {
    instance.viewerManager = createViewerManager(initialData, instance)
}

export {name, functionLibrary, defaultModel, init}
