import {getPointer} from '../../utils/pointerUtils'
const name = 'MultilingualPointerAspect'
const pointerType = 'multilingual'
const MULTILINGUAL_CURRENT_LANG_CODE = 'currentLanguageCode'

const createPointersMethods = () => {
    const currentLanguageCode = () => getPointer(MULTILINGUAL_CURRENT_LANG_CODE, pointerType)

    return {
        multilingual: {
            currentLanguageCode
        }
    }
}

const functionLibrary = {
    pointer_type_multilingual: createPointersMethods
}

export {
    name,
    functionLibrary,
    pointerType,
    MULTILINGUAL_CURRENT_LANG_CODE
}
