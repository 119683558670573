// @ts-check
const {VIEW_MODES} = require('../constants/constants')
const {deepClone} = require('immutableProxy')

/**
 * @param {Pointer} pointer
 * @return {Pointer}
 */
const stripInnerPath = pointer => {
    if (pointer.innerPath) {
        const ptr = {id: pointer.id, type: pointer.type}
        for (const key in pointer) {
            if (key !== 'innerPath' && !ptr.hasOwnProperty(key)) {
                ptr[key] = pointer[key]
            }
        }
        return ptr
    }
    return pointer
}

/**
 * @param {Pointer} pointer
 * @returns {Boolean}
 */
const isStructurePointer = pointer => !!VIEW_MODES[pointer.type]

/**
 * @param privates
 * @returns {ViewerManagerDAL}
 */
const createDAL = privates => {
    const typeToGetter = type => privates.getters[type]

    const typeToSetter = type => privates.setters[type]

    const hasGetter = type => !!typeToGetter(type)

    const hasSetter = type => !!typeToSetter(type)

    const sameViewMode = pointer => {
        const viewMode = privates.siteAPI.getViewMode()
        return viewMode === pointer.type
    }

    const get = (pointer, shouldClone = true) => {
        const getter = typeToGetter(pointer.type)
        if (!getter) {
            throw new Error(`No getter registered for type ${pointer.type}`)
        }
        const isStructurePtr = isStructurePointer(pointer)
        const isSameViewMode = !isStructurePtr || isStructurePtr && sameViewMode(pointer)
        const value = isSameViewMode ? getter(pointer.id, pointer.innerPath) : undefined
        return shouldClone ? deepClone(value) : value
    }

    const set = (pointer, value) => {
        const setter = typeToSetter(pointer.type)
        if (!setter) {
            throw new Error(`No setter registered for type ${pointer.type}`)
        }
        const previousValue = pointer.innerValue ? get(stripInnerPath(pointer)) : undefined //note that this value is cloned in get before we pass to the setter
        setter(value, pointer.id, pointer.innerPath, previousValue) //todo: #cloning - maybe clone this value, dont clone in GSD, save double clone from GSD to DM (through store)
    }

    const isExist = pointer => !!get(pointer, false)

    const remove = pointer => set(pointer, undefined)

    return {
        get,
        set,
        remove,
        isExist,
        hasGetter,
        hasSetter
    }
}

module.exports = {
    createDAL
}
