const name = 'privateApiMeasuringExtension'

const getMeasuringPrivateApis = siteApiData => ({
        getScreenSize: () => siteApiData.measuring.screenSize,
        getSiteX: () => siteApiData.measuring.siteX,
        getSiteWidth: () => siteApiData.measuring.siteWidth,
        getScreenHeight: () => siteApiData.measuring.screenHeight,
        getScreenWidth: () => siteApiData.measuring.screenWidth
    })

const functionLibrary = {
    getMeasuringPrivateApis
}

export {name, functionLibrary}
