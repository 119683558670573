const React = require('react')
const ReactDOM = require('react-dom')
const {getFunctionsLibrary} = require('carmi-react')
const EventEmitter = require('events')

const {utils, Renderer} = require('santa-renderer')
const modelFactory = require('./fragment.carmi')

const {hostRuntimeUtils: {buildFuncLib, initInstance, buildDefaultModel}} = utils
const Layout = require('bolt-responsive/src/aspects/responsiveLayout/responsiveLayout')
const Logger = require('carmi-host-extensions/src/aspects/logger/logger')
const WindowObject = require('carmi-host-extensions/src/aspects/windowObject/windowObject')
const Browser = require('carmi-host-extensions/src/aspects/browser/browser')
const Topology = require('./serviceTopologyExtension_codeDuplicationFromAdapter_sad')

const eventsManager = new EventEmitter()
const runtimeAspects = [Layout, Logger, WindowObject, Browser, Topology]
const functionLibrary = buildFuncLib(eventsManager, runtimeAspects, {}, {
    ...getFunctionsLibrary(),
    detectBrowser: () => Browser.functionLibrary.detectBrowser(window.navigator.userAgent),
    highlightAnchorsInMenu: () => true,
    registerComponentToAnchorChange: () => {},
    unregisterComponentToAnchorChange: () => {}
})

const loadFragment = (props, fragment, serviceTopology, onReady) => {
    const getWindowObject = () => window
    const model = {
        ...buildDefaultModel(runtimeAspects),
        getWindowObject,
        ...props,
        serviceTopology,
        meshResults: {},
        viewModeSwitchCount: {count: 0},
        loadedFonts: {},
        uploadedFonts: {}
    }
    const instance = modelFactory(model, functionLibrary)
    const initialized = initInstance(instance, eventsManager, runtimeAspects)
    ReactDOM.render(React.createElement(Renderer, {instance: initialized}), fragment, () => {
        onReady()
    })
}

const getBoltFragment = (hostReact, serviceTopology, onReady) =>
    class BoltFragment extends hostReact.Component {
        render() {
            return hostReact.createElement('div', {
                id: this.props.rootId,
                ref: ref => loadFragment(this.props, ref, serviceTopology, onReady),
                ...this.props.extraEventsHandlers
            })
        }
    }

module.exports.getBoltFragment = getBoltFragment
